import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@shared/ui/inputs/Checkbox';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { useDeleteUserMutation } from '@shared/api/current-user';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { ErrorIcon } from '@shared/ui/sidebars/icons';
import { BodyMedium, TextEmphasizedMediumBold } from '@components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import SidebarButtonsContainer from '../components/SidebarButtonsContainer';
import SidebarContainer from '../components/SidebarContainer';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: '100%',
    maxWidth: 420,
}));
const MessageContainer = styled.div(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
}));
const CheckboxContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    cursor: 'pointer',
    flexShrink: 0,
}));
const Confirmation = ({ onCancel, }) => {
    const { t } = useTranslation();
    const { goNext } = useWizard();
    const { toast } = useToastNotification();
    const [deleteAccountMutation, { error, isLoading, isSuccess, isError, },] = useDeleteUserMutation();
    const [userAgreeChecked, setUserAgreeChecked] = useState(false);
    const deleteAccount = () => {
        deleteAccountMutation();
    };
    const toggleCheckbox = () => {
        setUserAgreeChecked(!userAgreeChecked);
    };
    if (!isLoading && isError) {
        toast({
            message: JSON.stringify(error),
            variant: 'error',
        });
        onCancel();
    }
    if (!isLoading && isSuccess) {
        goNext();
    }
    return (_jsxs(SidebarContainer, { children: [_jsxs(Container, { children: [_jsxs(MessageContainer, { children: [_jsx(ErrorIcon, {}), _jsx(TextEmphasizedMediumBold, { children: t('settings.sidebars.delete_account.account_deletion') })] }), _jsxs(CheckboxContainer, { children: [_jsx(Checkbox, { checked: userAgreeChecked, onClick: toggleCheckbox }), _jsx(BodyMedium, { onClick: toggleCheckbox, children: t('settings.sidebars.delete_account.deletion_confirmation_checkbox_text') })] })] }), _jsxs(SidebarButtonsContainer, { children: [_jsx(Button, { color: 'error', onClick: deleteAccount, isLoading: isLoading, disabled: !userAgreeChecked || isLoading, fullWidth: true, children: t('settings.sidebars.delete_account.actions.delete') }), _jsx(Button, { variant: 'tinted', onClick: onCancel, fullWidth: true, children: t('actions.cancel', { ns: 'common' }) })] })] }));
};
export default Confirmation;
